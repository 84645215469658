<template>
    <zoznam-kamenov :items="akcioveProdukty" />
</template>

<script>
import ZoznamKamenov from './ZoznamKamenov.vue'
export default {
  components: { ZoznamKamenov },
    name: "Akciovy-tovar",
    props: {
        ponukaKamena: Array
    },
    data () {
        return {
            akcioveProdukty: [],
        }
    },
    mounted () {
        console.debug(1);
        this.findAkciovyTovar();
        console.debug(this.akcioveProdukty);
    },
    methods : {
        findAkciovyTovar() {
            if (this.ponukaKamena !== undefined && this.ponukaKamena != null) {
                this.ponukaKamena.forEach(kat => {
                    if (kat.subcategories) {
                        kat.subcategories.forEach(sub => {
                            this.akcioveProdukty.push(... this.getAkciovePolozkyFromKategoria(sub));
                        });
                    } else {
                        this.akcioveProdukty.push(... this.getAkciovePolozkyFromKategoria(kat));
                    }
                });
            }
        },
        getAkciovePolozkyFromKategoria (kat) {
            if (kat === undefined || kat == null || kat.items === undefined ) return [];
            var items = [];
            kat.items.forEach(i => {
                if (i.cena && i.cena.akciova_cena) {
                    items.push(i);
                }
            });
            return items;
        }
    }
}
</script>