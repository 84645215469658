<template>
  <section class="site-section block__62272" id="next-section">
    <div class="container">
      <div class="row mb-3">
        <div class="col-12 text-center" data-aos="fade">
          <h2 class="section-title mb-3">{{ title }}</h2>
        </div>
      </div>
      <Breadcrumb :pages="breadcrumb" v-if="breadcrumb != null" />
      <div v-if="kategoria != null && kategoria.description">
          <p v-for="line in splitByN(kategoria.description)" :key="line">{{line}}</p>
          <hr />
      </div>
      <ZoznamKamenov
        :items="kategoria.items"
        v-if="kategoria != null && kategoria.items != null"
      />
      <ZoznamKategorii
        :kategorie="kategoria.subcategories"
        v-else-if="kategoria != null && kategoria.subcategories != null"
      />
      <ProduktyGaleria
        :images="kategoria.gallery"
        v-else-if="kategoria != null && kategoria.gallery != null"
      />
      <akciovy-tovar
        :ponukaKamena="ponukaKamena"
        v-else-if="kategoria != null && kategoria.spec == 'akcie'"
      />
      <ZoznamKategorii :kategorie="ponukaKamena" v-else />
    </div>
  </section>
</template>

<script>
import ZoznamKategorii from "@/components/ZoznamKategorii.vue";
import ZoznamKamenov from "@/components/ZoznamKamenov.vue";
import ProduktyGaleria from "@/components/ProduktyGaleria.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AkciovyTovar from '../components/AkciovyTovar.vue';

export default {
  name: "ponuka-kamena",
  components: {
    ZoznamKategorii,
    ZoznamKamenov,
    Breadcrumb,
    ProduktyGaleria,
    AkciovyTovar
  },
  metaInfo() {
    return {
      title: this.metaTagy.title,
      meta : [
        { name:"title", content: this.metaTagy.title },
        { name:"description", content: this.metaTagy.description },
        { name:"keywords", content: this.metaTagy.keywords },
        { property:"og:type", content:"website" },
        { property:"og:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"og:title", content: this.metaTagy.title },
        { property:"og:description", content: this.metaTagy.description },
        { property:"twitter:card", content:"summary_large_image" },
        { property:"twitter:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"twitter:title", content: this.metaTagy.title },
        { property:"twitter:description", content: this.metaTagy.description },
      ]
    }
  },
  data() {
    return {
      metaTagy: {
        title: "Ponuka kameňa | Predaj prírodného kameňa",
        keywords: "kontakt, andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description:"Kontaktné údaje na predajci prírodného kameňa v Banskej Bystrici.",
        urlPath: this.$router.fullpath
      },
      title: "Ponuka kameňa",
      ponukaKamena: null,
      kategoria: null,
      breadcrumb: null,
      akciovyTovar: [],
    };
  },
  async mounted() {
    this.$axios
      .get("/data/ponuka-kamena.json")
      .then((response) => this.setData(response.data))
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    setData(data) {
      this.ponukaKamena = data;
      this.setKategoria();
      this.setTitle();
      this.setMetaTagy();
    },
    setTitle() {
      if (this.kategoria != null) {
        this.title = this.kategoria.title;
      }
    },
    setKategoria() {
      if (this.$route.params.kategoria != undefined) {
        this.breadcrumb = [];
        this.breadcrumb.push({title:'Predaj kameňa', href:'/ponuka-kamena'});
        this.ponukaKamena.forEach((kategoria) => {
          if (kategoria.slug == this.$route.params.kategoria) {
            this.breadcrumb.push({title:kategoria.title, href:'/ponuka-kamena/' + kategoria.slug});

            if (this.$route.params.subkategoria != undefined) {
              kategoria.subcategories.forEach((subcat) => {
                if (subcat.slug == this.$route.params.subkategoria) {
                  this.breadcrumb.push({title:subcat.title, href:'/ponuka-kamena/' + kategoria.slug + '/' + subcat.glug});
                  this.kategoria = subcat;
                }
              });
            } else {
              this.kategoria = kategoria;
            }
          }
        });
      }
    },
    setMetaTagy () {
      this.metaTagy = {
        title: this.title + " | Predaj prírodného kameňa",
        keywords: "kontakt, andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description:"Ponuka prírodného kameňa od predajcu v Banskej Bystrici.",
        urlPath: this.$router.fullpath
      };
    },
    setAkciovyTovar () {

    },
    splitByN (string) {
      return string.split("\n");
    }
  },
};
</script>