<template>
    <div class="row no-gutter">
        <div
          class="item col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4 text-center"
          v-for="kategoria in kategorie"
          :key="kategoria.order"
        >
          <a :href="$route.path + '/' + kategoria.slug" class="item-wrap">
            <img
              class="img-fluid"
              :src="kategoria.image"
              v-if="kategoria.image"
            />
            <img
              class="img-fluid"
              src="/images/kamen/kategorie/no_image_cat.png"
              v-else
            />
            <p class="text-center">
              <strong>{{ kategoria.title }}</strong>
            </p>
          </a>
        </div>
      </div>
</template>
<script>
export default {
    props:{
        'kategorie' : Array
    },

}
</script>