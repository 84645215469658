<template>
  <div class="row no-gutter">
    <div
      class="item col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4 text-center"
      v-for="item in items"
      :key="item.name"
    >
      <router-link
        class="item-wrap"
        :to="{ name: 'kamenDetail', params: { kamenSlug: item.slug  } }"
      >
        <img class="img-fluid" :src="getThumbImage(item.images)" />
        <img v-if="item.cena && item.cena.akciova_cena" class="saleIcon" src="/images/sale_icon.png" />
        <p class="text-center">
          <strong>{{ item.name }}</strong>
        </p>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: Array
  },
  mounted () {
    this.sortItems();
  },
  methods: {
    sortItems () {
      this.items.sort(function (a,b) {
        return a.name.localeCompare(b.name);
      });
    },
    getThumbImage(images) {
      if (images == null || images.length == 0) {
        return "/images/kamen/kategorie/no_image_cat.png";
      }

      for (const img of images) {
        if (img.default !== undefined && img.default == true) {
          if (img.thumb !== undefined) {
            return img.thumb;
          } else {
            return img.full;
          }
        }
      }

      if (images[0].thumb !== undefined) {
        return images[0].thumb;
      } else {
        return images[0].full;
      }
    },
  },
};
</script>

<style lang="scss">
.saleIcon {
  z-index: 10;
  border: 0;
  position: absolute !important;
  top: 225px;
  right: 25px;
 }
</style>